.amplify-text {
  color: var(--reading-text-color);;
}
.amplify-text--primary {
  color: var(--primary-color);
}
.amplify-text--success {
  color: var(--success-color);
}
.amplify-text--info {
  color: var(--info-color);
}
.amplify-text--warning {
  color: var(--warning-color);
}
.amplify-text--danger {
  color: var(--danger-color);
}

.amplify-textareafield {
  color: var(--reading-text-color);;
}
.amplify-textarea {
  color: var(--reading-text-color);;
}

.amplify-alert {
  color: var(--reading-text-color);;
}
.amplify-alert--primary {
  background-color: var(--primary-color);
}
.amplify-alert--success {
  background-color: var(--success-color);
}
.amplify-alert--info {
  background-color: var(--info-color);
}
.amplify-alert--warning {
  background-color: var(--warning-color);
}
.amplify-alert--danger {
  background-color: var(--danger-color);
}

.amplify-alert--danger {
  background-color: var(--danger-color);
}
.amplify-button {
  border: none;
  color: white;
  background-color: var(--secondary-color-1);
}
.amplify-button--primary {
  background-color: var(--primary-color);
}
.amplify-button--success {
  background-color: var(--success-color);
}
.amplify-button--info {
  background-color: var(--info-color);
}
.amplify-button--warning {
  background-color: var(--warning-color);
}
.amplify-button--danger {
  background-color: var(--danger-color);
}

.amplify-button:hover {
  color: var(--secondary-color-1);
  background-color: white;
}
.amplify-button--primary:hover {
  color: var(--primary-color);
  background-color: white;
}
.amplify-button--success:hover {
  color: var(--success-color);
  background-color: white;
}
.amplify-button--info:hover {
  color: var(--info-color);
  background-color: white;
}
.amplify-button--warning:hover {
  color: var(--warning-color);
  background-color: white;
}
.amplify-button--danger:hover {
  color: var(--danger-color);
  background-color: white;
}

.amplify-heading {
  color: var(--reading-text-color);;
}
.amplify-tabs-item {
  color: var(--reading-text-color);;
}
.amplify-tabs-item:focus {
  color: var(--secondary-color-1);
}
.amplify-tabs-item:hover {
  color: var(--secondary-color-1);
}

.amplify-tabs-item[data-state="active"] {
  border-color: var(--secondary-color-1);
  color: var(--secondary-color-1);
}
[data-amplify-authenticator] [data-state="inactive"] {
  background-color: rgba(49, 3, 63, 0.5);
}

.App {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  align-content: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.content {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  color: var(--reading-text-color);;
  overflow: hidden;
}

/* .historyCard {
  all: unset;
  background-color: var(--reading-text-background-color);
  border: 1px solid;
  border-color: 1px solid #242424;
  border-radius: 2px;
  color: var(--reading-text-color);;
}

.historyCard:hover {
  box-shadow: var(--box-shadow-hover);
} */

.slide-in {
  opacity: 0;
  transform: translateX(-50%);
  animation: slide-in 0.5s ease-in forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
