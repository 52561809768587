.videoStatusBadge {
  position: absolute;
  bottom: 0;
  left: 10;
  font-size: small;
  padding: 2px;
  margin: 2px;
  text-transform: capitalize;
}

.videoDescription {
  color: var(--reading-text-color);
  height: 100px;
  overflow: hidden;
  font-size: 12px;
}

.historReadItemContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100%;
  width: 15%;
  min-width: 85px;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;
  display: flex;
  cursor: pointer;
  color: rgb(208, 208, 208);
  font-size: 1rem;
  font-weight: 600;
}
.videoReadItemButton {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color-1);
}

.videoDeleteItemButton {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--danger-color);
}

.videoReadItemButton:hover {
  background-color: var(--primary-color-2);
}

.videoDeleteItemButton:hover {
  background-color: red;
}
