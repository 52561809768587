.containerFluid {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}

.navbar {
  /* box-shadow: var(--box-shadow); */
  background-image: linear-gradient(
    to left,
    var(--primary-color-2),
    var(--primary-color-3) 110%
  );
  border-image: linear-gradient(
      to left,
      var(--secondary-color-1),
      rgb(0, 0, 0) 110%
    )
    1;
  display: flex;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  height: 4.5rem;
  top: 0px;
  width: 100%;
}

.navbarBrand {
  display: flex;
  width: 140px;
  height: 100%;
  text-align: center;
  vertical-align: bottom;
  border-radius: 0 10px 10px 0;
  margin-left: 30px;
  margin-top: 15px;
}

.logo {
  height: 50%;
  margin: auto;
  align-self: baseline;
}

.navItem {
  display: flex;
  text-decoration: none;
  color: var(--primary-color-1);
  opacity: 0.7;
  font-size: 1.2rem;
  font-weight: 600;
  margin: auto 4rem;
  height: 100%;
  align-items: bottom;
  vertical-align: bottom;
}
.navItem:hover {
  color: var(-- -color-3);
}
.navbar > .navItem:hover {
  opacity: 1;
  color: var(--secondary-color-1);
}

.active {
  opacity: 1;
  color: var(--secondary-color-1);
  border-bottom: 2px solid var(--secondary-color-1);
}

.navbarNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto 0;

  height: 100%;
  align-items: center;
  vertical-align: middle;
}

.justifyContentEnd {
  justify-content: flex-end;
  margin-left: auto;
}

.navItemRight {
  margin-left: left;
  right: 30px;
  padding-top: 30px;
}

.connectButton {
  border: none;
  box-shadow: none;
  background-color: var(--secondary-color-3);
  transform: translateZ(0);
  transition: 0.25s ease-out;
  border-radius: 5px;
  vertical-align: middle;
  padding: 0px 10px;
}
.connectButton > * {
  border: none;
}

.link {
  text-decoration: none;
  color: white;
  opacity: 0.7;
  font-size: 1.2rem;
  font-weight: 400;
  height: 100%;
  align-items: bottom;
  vertical-align: bottom;
  cursor: pointer;
  margin: auto 0.5rem;
}

.link:hover {
  opacity: 1;
}
