.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.subscriptionCard {
  background-color: #f1f1f152;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 0 20px;
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  color: #f4f4f4;
  cursor: pointer;
}
.subscriptionCard:hover {
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
}
.subscriptionCard > h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 20px;
  color: #000;
}
.subscriptionCard > p {
  margin: 0 0 20px;
  color: #000;
}
.iconArea {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0 auto;
  color: var(--success-color);
}
