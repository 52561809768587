.synopsisTab {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.synopsis {
  font-size: 1.25em;
  text-align: left;
  max-width: 100%;
  height: 100%;
  overflow: auto;
}
.inputDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.amplify-textfield {
  --amplify-components-fieldcontrol-border-color: rebeccapurple;
}

.selectItemDiv {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.checkboxDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
  margin: 10px;
  font-size: 1rem;
}
.checkbox {
  margin-right: 10px;
  cursor: pointer;
}

.clickableText {
  cursor: pointer;
  color: var(--reading-text-color);
}
.clickableText:hover {
  cursor: pointer;
  text-decoration: underline;
}

.historyContainer {
  position: absolute;
  top: var(--padding-common);
  bottom: var(--padding-common);
  left: var(--padding-common);
  right: var(--padding-common);
  overflow: auto;
  padding: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.summaryContainer {
  position: absolute;
  top: 475px;
  bottom: var(--padding-common);
  left: var(--padding-common);
  right: var(--padding-common);
  width: 100%;
  overflow: auto;
  padding: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cardContainer {
  justify-content: center;
  border: 1px solid var(--primary-color);
  background-color: var(--reading-text-background-color);
  border-radius: 10px;
  margin: 10px;
  width: 100%;
  height: 100%;
}

.clickableWord {
  cursor: pointer;
  color: var(--reading-text-color);
}
.clickableWord:hover {
  cursor: pointer;
  text-decoration: underline;
}
