.diagram-component {
  width: 1920px;
  height: 1200px;
  margin: 5px 5px;
  background-color: transparent;
  border: 2px solid green;
  z-index: 0;
}

.videoMapPlayer {
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 600px;
}
.videoMapPlayerCloseBtn {
  position: absolute;
  bottom: 25px;
  right: 25px;
}
