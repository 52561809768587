.historyCollectionCard {
  all: unset;
  background-color: var(--reading-text-background-color);
  border: 1px solid;
  border-color: 1px solid #242424;
  border-radius: 20px;
  color: var(--reading-text-color);
  position: relative;
  overflow: hidden;
}

.historyCard:hover {
  box-shadow: var(--box-shadow-hover);
}

.historyCardContent {
  padding-right: 60px;
  position: relative;
  overflow: hidden;
  padding: 10px;
}
.historyCardContentTitleHeading{
  font-size: 12px;
  white-space: nowrap;
  color: var(--primary-color-3);
}

.historyStatusBadge {
  position: absolute;
  bottom: 0;
  left: 10;
  font-size: small;
  padding: 2px;
  margin: 2px;
  text-transform: capitalize;
}

.historyDescription {
  color: var(--reading-text-color);
  height: 100px;
  overflow: hidden;
  font-size: 10px;
}

.historReadItemContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100%;
  width: 15%;
  min-width: 85px;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;
  display: flex;
  cursor: pointer;
  color: rgb(208, 208, 208);
  font-size: 1rem;
  font-weight: 600;
}
.historyReadItemButton {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color-1);
}

.historyDeleteItemButton {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--danger-color);
}

.historyReadItemButton:hover {
  background-color: var(--primary-color-2);
}

.historyDeleteItemButton:hover {
  background-color: red;
}
