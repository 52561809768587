.scrollContainer {
  position: absolute;
  top: var(--padding-common);
  bottom: var(--padding-common);
  left: var(--padding-common);
  right: var(--padding-common);
  overflow: auto;
  padding: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
