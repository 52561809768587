.text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  margin: 0;
  padding: 0;
}

.summaryPointlist {
  padding-left: 0;
}
.scrollView {
  padding: 0px;
}
@media (min-width: 768px) {
  .text {
    font-size: 16px;
  }
  .summaryPointlist {
    padding-left: 10;
  }
  .scrollView {
    padding: 20px;
  }
}

@media (min-width: 1024px) {
  .text {
    font-size: 18px;
  }
  .summaryPointlist {
    padding-left: 15;
  }
}
