* {
  box-sizing: border-box;
  scrollbar-width: none; /* Firefox */
  color: rgb(226, 226, 226);
  transition: all 0.25s ease-in-out;
}

*::-webkit-scrollbar {
  display: none;
}

:root {
  --color-bg: rgb(58, 28, 157);
  --color-main: rgba(72, 42, 169, 0.2);

  --margin-common: 12px;
  --margin-double: 18px;
  --margin-half: 6px;

  --padding-common: 12px;
  --padding-double: 18px;
  --padding-half: 6px;

  font-family: "Montserrat", sans-serif;

  --primary-color: #042b6a;
  --success-color: #5eb53c;
  --info-color: #4c6c9c;
  --warning-color: #b5933c;
  --danger-color: #b53c5e;

  --reading-text-color: #000;
  --reading-text-background-color: rgb(245, 245, 245);

  /* New data */
  --primary-color-1: #042b6a;
  --primary-color-2: #065cac;
  --primary-color-3: #4c6c9c;
  --primary-color-4: #5e7c8c;
  --primary-color-5: #9ec0e4;

  --secondary-color-1: #042b6a;
  --secondary-color-2: #888292;
  --secondary-color-3: #735086;
  --chat-border-color: #735086;



  --primary-color-1-trans: #f7f7f750;
  --primary-color-2-trans: #dedce050;
  --primary-color-3-trans: #ffffff50;
  --secondary-color-1-trans: #53455b50;
  --secondary-color-2-trans: #88829250;
  --secondary-color-3-trans: #73508650;
}

body {
  margin: 0;
  font-family: "Verdana", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:  #9ec0e4;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
